html {
  background: white;
  font-family: Verdana;
}
body {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
#stl-logo {
  background-color: rgba(255, 255, 255, 0.95);
  top: 0;
  left: 0;
  position: absolute;
  margin-left: 10px;
  margin-top: 10px;
  padding-top: 30px;
  padding-bottom: 30px;
  border: 2px solid silver;
}
#togglePause {
  position: absolute;
  width: 70px;
}
#startSync {
  position: absolute;
  width: 82px;
}
#initialSyncError {
  position: absolute;
  color: red;
  text-align: right;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10000;
  border: 2px solid red;
  border-radius: 10px;
  padding: 10px;
}
.container-border {
  margin: 4px;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.66);
  border: 2px solid white;
  border-radius: 12px;
}
.splash-circle {
  background: radial-gradient(ellipse closest-side, rgba(255, 255, 255, 0.33) 20%, rgba(255, 255, 255, 0) 100%);
}
#spash-circle-top {
  top: 0;
  height: 20px;
  left: 0;
  right: 0;
  position: absolute;
}
#spash-circle-bottom {
  bottom: 0;
  height: 20px;
  left: 0;
  right: 0;
  position: absolute;
}
profile-globe {
  margin: 0;
  padding: 0;
  overflow: hidden;
  top: 4px;
  left: 4px;
  right: 4px;
  bottom: 4px;
  position: absolute;
  border-radius: 8px;
}
button.btn-custom-dark {
  background: #000;
  color: #fff;
  border: 2px solid white;
  z-index: 10001;
  opacity: 0.2;
}
button.btn-custom-dark:hover {
  opacity: 1;
}
button.btn-custom-dark:hover,
button.btn-custom-dark:focus,
button.btn-custom-dark:active {
  color: #fff;
  outline: 0;
}
.profileCount:hover .profileCountTooltip {
  visibility: visible;
}
.profileCountTooltip {
  visibility: hidden;
  position: absolute;
  z-index: 100001;
}
.profileCount {
  position: fixed;
  right: 15px;
  top: 15px;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10000;
  font-size: 38px;
  border: 2px solid silver;
  border-radius: 10px;
  padding: 10px;
}
#profileListPlacement {
  position: absolute;
  z-index: 1000;
  right: 4px;
  bottom: 4px;
  overflow: hidden;
  border-radius: 8px;
}
#profileListContainer {
  position: absolute;
  border-width: 0px;
  color: white;
  z-index: 1000;
  display: inline-block;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.profile {
  border: 4px solid silver;
  border-radius: 15px;
  text-align: right;
  padding: 8px;
  margin: 8px;
  background: black;
  opacity: 0.1;
  font-size: 16px;
}
.profile:nth-child(5) {
  opacity: 0.2;
}
.profile:nth-child(4) {
  opacity: 0.3;
}
.profile:nth-child(3) {
  opacity: 0.4;
}
.profile:nth-child(2) {
  opacity: 0.5;
}
.profile:first-child {
  opacity: 1;
}
.profile .consumer {
  font-size: 2em;
}
.profile .account {
  font-size: 1.5em;
}
.prominentProfile div.profile {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0);
  min-width: 185px;
  text-align: left;
  font-size: 18px;
}
.prominentProfile div.profile.ng-enter {
  opacity: 0;
  transition: all ease-in 0.25s;
}
.prominentProfile div.profile.ng-enter.ng-enter-active {
  opacity: 1;
}
.prominentProfile div.profile.ng-leave {
  opacity: 1;
  transition: all ease-in 0.25s;
}
.prominentProfile div.profile.ng-leave.ng-leave-active {
  opacity: 0;
}
@media screen and (orientation: landscape) {
  #profileListContainer,
  li.profile {
    -webkit-transform: scaleY(-1);
    -moz-transform: scaleY(-1);
    -ms-transform: scaleY(-1);
    -o-transform: scaleY(-1);
    transform: scaleY(-1);
  }
  #profileListPlacement {
    height: calc(100% - 8px);
    width: 250px;
  }
  #profileListContainer {
    max-width: 250px;
    bottom: 0px;
    right: 0px;
  }
  #togglePause {
    bottom: 35px;
    left: 10px;
    position: absolute;
  }
  #startSync {
    bottom: 35px;
    left: 85px;
  }
  #initialSyncError {
    bottom: 66px;
    left: 6px;
  }
}
@media screen and (orientation: portrait) {
  #profileListPlacement {
    height: 250px;
    width: calc(100% - 8px);
    bottom: 35px;
    border-radius: 0;
  }
  #profileListContainer {
    width: 1000%;
    max-height: 185px;
    bottom: 0px;
    left: 0px;
  }
  li.profile {
    min-width: 185px;
    text-align: left;
    display: inline-block;
  }
  #togglePause {
    top: 90px;
    right: 10px;
  }
  #startSync {
    top: 90px;
    right: 85px;
  }
  #initialSyncError {
    top: 123px;
    right: 5px;
  }
}
